.content {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.redPacket {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
