







































































































































































































































































































































































































































.all_application {
  .el-upload {
    max-width: 200px;
  }
}
