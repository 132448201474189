


































































































































































































































































































































































































































.content {
  width: 100%;
  overflow-x: scroll; // 子元素的宽度大于父元素的即可滚动
  overflow-y: hidden;
}
.redPacket {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: left; // 使其脱离文档流 宽度为所有字元素的和
  // min-width: 100%;
}
